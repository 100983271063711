import 'lenis/dist/lenis.css'
import Lenis from "lenis";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

window.addEventListener('DOMContentLoaded', () => {
   setTimeout(() => {
      
      const marqueeContainer = document.querySelectorAll(".marquee-container");
      
      marqueeContainer.forEach((container) => {
         const marqueeContent = container.children[0];
         const duplicatedContent = marqueeContent.cloneNode(true);
         container.appendChild(duplicatedContent);
      
         const contentWidth = marqueeContent.offsetWidth;
      
         gsap.to(container.children, {
            x: -contentWidth,
            duration: 20 + Math.floor(Math.random() * 4),
            ease: "linear",
            repeat: -1,
            onRepeat: function () {
               gsap.to(".marquee-content", {
                  delay: 0.1,
                  x: 0,
                  duration: 0
               });
            },
         });
      });
      
      gsap.registerPlugin(ScrollTrigger);
      if (document.querySelector('.pin-text')) {
         // Pin & Scroll Text
         gsap.to(".pin-text", {
            x: () => -(document.querySelector(".pin-text").offsetWidth - document.querySelector(".pin-text-container").offsetWidth + 50),
            ease: "power2.out",
            duration: 2,
            scrollTrigger: {
               trigger: ".pin-text-container",
               pin: true,
               scrub: 0.5,
               start: "top top",
               end: "bottom top"
            }
         });
      }
      
      // Logo
      if (document.querySelector('.logo-container')) {
         gsap.to(".logo", {
            y: -500,
            opacity: 0,
            filter: "blur(10px)",
            ease: "power2.out",
            duration: 2,
            scrollTrigger: {
               trigger: ".logo-container",
               scrub: 1,
               start: "top top",
               end: "+=300"
            }
         });
      }
      // Card Stack
      if (window.innerWidth >= 1280 && document.querySelector('.card-container')) {
         let cards = gsap.utils.toArray(".card");
         let stickDistance = 1950;
         let tl = gsap.timeline();
      
         cards.forEach((card, index) => {
            tl.from(card, {
               y: 800,
               opacity: 0,
               duration: 2
            });
         });
      
         let pinTL = gsap.timeline({
            scrollTrigger: {
               trigger: ".card-container",
               start: "top 0",
               end: () => "+=" + stickDistance,
               pin: true,
               pinSpacing: false,
               scrub: 1,
            }
         });
      
         pinTL.add(tl); // Add the animation timeline to the pinning timeline
      }
      
      // Cursor
      const cursor = document.querySelector("#cursor");
      const moveCursor = (x, y) => {
         gsap.to(cursor, {
            duration: 0.5,
            left: x,
            top: y,
            ease: 'power2.out'
         });
      };
      document.addEventListener('mousemove', e => {
         moveCursor(e.clientX, e.clientY);
      });
      
      // Smooth Scroll
      const lenis = new Lenis()
      lenis.on('scroll', ScrollTrigger.update)
      
      gsap.ticker.add((time)=>{
      lenis.raf(time * 800)
      })
      
      gsap.ticker.lagSmoothing(0)
   }, 1000);
})

