import Alpine from "alpinejs"
import collapse from "@alpinejs/collapse"
import focus from "@alpinejs/focus"
import intersect from "@alpinejs/intersect"
import breakpoint from "alpinejs-breakpoints"
Alpine.plugin(intersect)
Alpine.plugin(breakpoint)
Alpine.plugin(collapse)
Alpine.plugin(focus)
window.Alpine = Alpine
window.AlpineBreakpointPluginBreakpointsList = [
	"xs",
	"sm",
	"md",
	"lg",
	"xl",
	"2xl",
	"3xl",
]
window.Alpine.start()


import "./modules/matter"
import "./modules/gsap"